<template>
  <v-data-table
    v-if="items && items.length"
    class="dt-listing-simple"
    :headers="headers"
    :items="items"
    :loading="bLoading"
  >
    <template #[`item.name`]="{ item }">
      <currency-preview :item="item" />
    </template>

    <template #[`item.round`]="{ item }">
      <currency-rate-round-select :currency="item" :company-id="obCompany.id" />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import { Company, CurrencyRates } from "@planetadeleste/vue-mc-gw";
import type {
  Currency,
  CurrencyCollection,
} from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import CurrencyRateRoundSelect from "@/modules/currencyrates/components/CurrencyRateRoundSelect.vue";

@Component({
  components: { CurrencyRateRoundSelect, CurrencyPreview },
})
export default class CurrencyRateRound extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
  arCurrencyIdList: number[] = [];
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
    { text: "symbol", value: "symbol", sortable: false },
    { text: "currency.round", value: "round", sortable: false },
  ];
  bLoading = false;

  get currencies(): CurrencyCollection {
    return AppModule.currencies;
  }

  get items() {
    return this.arCurrencyIdList.length
      ? this.currencies
          .filter<CurrencyCollection>((obItem: Currency) =>
            this.arCurrencyIdList.includes(obItem.id)
          )
          .getModelList()
      : [];
  }

  async mounted() {
    this.mapDTHeaders();
    await this.load();
  }

  async load() {
    if (!this.obCompany.id) {
      return;
    }

    this.bLoading = true;

    // Load company currencies
    const obCompanyCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCompanyCurrencyRateCollection
      .filterBy({ companyGrouped: this.obCompany.id })
      .fetch();
    this.arCurrencyIdList = obCompanyCurrencyRateCollection.map("currency_id");

    this.bLoading = false;
  }
}
</script>
