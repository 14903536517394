<template>
  <v-select
    v-model="roundBy"
    :label="$t('currency.round')"
    :items="items"
    :loading="loading"
    outlined
    dense
    hide-details
    @change="onChange"
  />
</template>

<script lang="ts">
import { CurrencyRate, CurrencyRates } from "@planetadeleste/vue-mc-gw";
import type { Currency, CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/config";
import { AppModule } from "@/store/app";

@Component
export default class CurrencyRateRoundSelect extends Vue {
  @Prop(Number) readonly companyId!: number;
  @Prop(Object) readonly currency!: Partial<CurrencyData> | Currency;

  obCurrencyRate: CurrencyRate | undefined | null = null;
  loading = false;

  mounted() {
    this.load();
  }

  onChange(fValue: number) {
    if (!this.obCurrencyRate) {
      return;
    }

    this.obCurrencyRate.set("round", fValue);
    this.$emit("change", fValue);
    this.save();
  }

  async load() {
    if (!this.currency || !this.companyId || !this.currency.id) {
      return;
    }

    this.loading = true;
    const obCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCurrencyRateCollection
      .filterBy({
        company: this.companyId,
        currency: this.currency.id,
        rounded: 1,
      })
      .fetch();

    this.obCurrencyRate = new CurrencyRate({
      company_id: this.companyId,
      currency_id: this.currency.id,
    });

    const obCurrencyRate = obCurrencyRateCollection.first();

    if (obCurrencyRate) {
      this.obCurrencyRate.set("round", obCurrencyRate.round);
    }

    this.loading = false;
  }

  async save() {
    // Get out if CurrencyRate is not passed as prop
    if (!this.obCurrencyRate) {
      return;
    }

    // Get out if value has not changed
    if (!this.obCurrencyRate.isDirty("round")) {
      return;
    }

    this.loading = true;

    this.obCurrencyRate.sync();
    await this.obCurrencyRate.store();

    if (this.companyId === AppModule.company.id) {
      await ConfigModule.loadCompanyCurrencyRates(true);
    }

    this.loading = false;
  }

  get items() {
    return [
      { text: this.$t("without.rounded"), value: 0 },
      { text: "0.01", value: 0.01 },
      { text: "0.10", value: 0.1 },
      { text: "1.00", value: 1 },
      { text: "10.00", value: 10 },
      { text: "100.00", value: 100 },
    ];
  }

  get roundBy(): number {
    return this.obCurrencyRate ? this.obCurrencyRate.get("round", 0) : 0;
  }

  set roundBy(sValue: number) {
    if (!this.obCurrencyRate) {
      return;
    }

    this.obCurrencyRate.set("round", 0);
  }
}
</script>
